import queryString from 'query-string'
import {Location} from '@reach/router'

import {Grid, Flex} from 'src/components/Box'
import popularSearches from 'src/components/popularSearches'
import {localPaths} from 'src/urls'
import {PrimaryLink} from 'src/components/Link'

import {Header3, SmallBody} from './text'

const NoMoreJobs = () => {
  return (
    <Location>
      {({location}) => {
        const query = queryString.parse(location.search)
        // NOTE: cast to string since queryString is typed as possibly an array
        const searchLocation = String(query.location || '')
        return (
          <Grid p="4" gridRowGap="2" gridAutoFlow="row">
            <Header3>
              You've hit the end of the results{' '}
              <span role="img" aria-label="sweat smile emoji">
                😅
              </span>
            </Header3>
            <SmallBody>Try something different?</SmallBody>
            <Flex flexDirection="column" alignItems="flex-start">
              <PrimaryLink
                to={localPaths.jobSearchResults({location: searchLocation})}
              >
                See all jobs
              </PrimaryLink>
              {popularSearches.map(({value, label}) => (
                <PrimaryLink
                  key={value}
                  to={localPaths.jobSearchResults({
                    location: searchLocation,
                    query: value,
                  })}
                >
                  {label}
                </PrimaryLink>
              ))}
            </Flex>
          </Grid>
        )
      }}
    </Location>
  )
}

export default NoMoreJobs
