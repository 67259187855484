import {useState, useRef, Fragment} from 'react'
import {Form, Field, FormSpy, useField} from 'react-final-form'
import styled from '@emotion/styled'

import {SortEnum, JobEnum} from 'src/graphql-generated'
import * as styles from 'src/styles'
import {Body, ExtraSmallBody, SmallBody} from 'src/components/text'
import {Action} from 'src/components/Action'
import {Divider} from 'src/components/dividers'
import RadioInput from 'src/components/RadioInput'
import {buttonCss} from 'src/styles/button'

import {SearchIcon, CaretRightIcon, LocationPinIcon, CloseIcon} from './icons'
import {Box, Grid, Flex} from './Box'

interface FormValues {
  location: string
  query: string
  sort: SortEnum
  jobType?: JobEnum
}

interface Props {
  onSubmit: (values: FormValues) => Promise<void> | void
  initialValues?: Partial<FormValues>
}

interface SuggestionGroup {
  name?: string
  items: {value: string; label: string}[]
}

const querySuggestions: SuggestionGroup[] = [
  {
    items: [
      {
        value: '',
        label: 'All Jobs',
      },
    ],
  },
  {
    name: 'Popular Searches',
    items: [
      {value: 'Retail', label: 'Retail'},
      {value: 'Barista', label: 'Barista'},
      {value: 'Customer Service', label: 'Customer Service'},
      {value: 'Health Care', label: 'Health Care'},
      {value: 'Food Service', label: 'Food Service'},
      {value: 'Childcare', label: 'Childcare'},
    ],
  },
]

const GoButton = styled.button(
  styles.borders.gradientBorder(styles.gradients.primaryButton),
  buttonCss.reset,
  buttonCss.border,
  styles.typography.body,
  ({theme}) => ({
    padding: [theme.space[0], theme.space[1]].join(' '),
  })
)

type EditSearchFieldPageProps = {
  fieldName: string
  placeholder: string
  suggestions?: SuggestionGroup[]
  closePage: () => void
}

const EditSearchFieldPage = ({
  fieldName,
  placeholder,
  suggestions = [],
  closePage,
}: EditSearchFieldPageProps) => {
  const fieldProps = useField<string>(fieldName)
  const [inputValue, setInputValue] = useState('')
  const goRef = useRef<HTMLButtonElement | null>(null)

  const onGo = () => {
    fieldProps.input.onChange(inputValue)
    closePage()
  }

  const createOnSuggestionSelect = (suggestionValue: string) => () => {
    fieldProps.input.onChange(suggestionValue)
    closePage()
  }

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Action onClick={closePage}>
          <CloseIcon width="50px" color="primary" />
        </Action>
        <input
          type="text"
          placeholder={placeholder}
          autoFocus
          value={inputValue}
          onChange={(e) => setInputValue(e.currentTarget.value)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              goRef.current && goRef.current.click()
            }
          }}
          css={{
            flex: '1 1 auto',
            ...styles.typography.body,
            border: 'none',
            padding: 0,
            margin: 0,
          }}
        />

        <GoButton ref={goRef} onClick={onGo} aria-label="Search Jobs">
          Go
        </GoButton>
      </Flex>
      <Divider thin mx={-2} />
      {suggestions.map(({name, items}, i) => (
        <Fragment key={[name, i].join('-')}>
          {name && <ExtraSmallBody color="grey4">{name}</ExtraSmallBody>}
          {items.map(({value, label}) => (
            <Action key={value} onClick={createOnSuggestionSelect(value)}>
              <Body textAlign="left">{label}</Body>
            </Action>
          ))}
        </Fragment>
      ))}
    </>
  )
}

interface RadioGroupOption {
  readonly value: string
  readonly label: string
}

const RadioGroup = ({
  name,
  groupLabel,
  options,
}: {
  name: string
  groupLabel: string
  options: RadioGroupOption[]
}) => (
  <Flex as="fieldset" flexDirection="column">
    <SmallBody>{groupLabel}</SmallBody>
    <Flex ml={-1} flexWrap="wrap">
      {options.map(({value, label}) => (
        <Field key={value} name={name} type="radio" value={value}>
          {(props) => (
            <Box mt="1" ml="1">
              <RadioInput {...props.input} type="radio" label={label} />
            </Box>
          )}
        </Field>
      ))}
    </Flex>
  </Flex>
)

const SortByOptions: RadioGroupOption[] = [
  {
    value: SortEnum.Relevance,
    label: 'Relevance',
  },
  {
    value: SortEnum.Date,
    label: 'Date Posted',
  },
]

const FilterOptions: RadioGroupOption[] = [
  {
    value: '',
    label: 'No Filters',
  },
  {
    value: JobEnum.Parttime,
    label: 'Part-Time',
  },
  {
    value: JobEnum.Fulltime,
    label: 'Full-Time',
  },
  {
    value: JobEnum.Temporary,
    label: 'Temporary',
  },
  {
    value: JobEnum.Internship,
    label: 'Internship',
  },
  {
    value: JobEnum.Contract,
    label: 'Contract',
  },
]

type InitialPageProps = {
  values: FormValues
  editQuery: () => void
  editLocation: () => void
  submit: () => void
}

const InitialPage = ({
  values,
  editQuery,
  editLocation,
  submit,
}: InitialPageProps) => (
  <>
    <FormSpy
      onChange={({pristine}) => !pristine && submit()}
      subscription={{values: true, pristine: true}}
    />
    <Action onClick={editQuery}>
      <Flex justifyContent="space-between" alignItems="center" mx="0.25rem">
        <Flex>
          <SearchIcon width="1.25rem" height="1.25rem" mr="1" />
          <Body>{values.query ? values.query : 'All Jobs'} </Body>
        </Flex>
        <CaretRightIcon width="0.75rem" height="0.75rem" />
      </Flex>
    </Action>
    <Divider thin mx={-2} />
    <Action onClick={editLocation}>
      <Flex justifyContent="space-between" alignItems="center" mx="0.25rem">
        <Flex>
          <LocationPinIcon
            color="primary"
            fill="currentColor"
            width="1.25rem"
            height="1.25rem"
            mr="1"
          />
          <Body>{values.location} </Body>
        </Flex>
        <CaretRightIcon width="0.75rem" height="0.75rem" />
      </Flex>
    </Action>
    <Divider thin mx={-2} />
    <RadioGroup name="sort" groupLabel="Sort by" options={SortByOptions} />
    <RadioGroup name="jobType" groupLabel="Filter" options={FilterOptions} />
  </>
)

const JobSearchForm = ({onSubmit, initialValues}: Props) => {
  const [editField, setEditField] = useState<'location' | 'query' | null>(null)
  const formRef = useRef<HTMLFormElement | null>(null)
  const submit = () => {
    if (!formRef.current) {
      return
    }

    formRef.current.dispatchEvent(
      new Event('submit', {
        bubbles: true,
        cancelable: true,
      })
    )
  }

  return (
    <Form<FormValues>
      initialValues={{
        location: '',
        query: '',
        sort: SortEnum.Relevance,
        jobType: undefined,
        ...initialValues,
      }}
      onSubmit={onSubmit}
    >
      {({handleSubmit, values}) => (
        <form ref={formRef} onSubmit={handleSubmit}>
          <Grid gridGap="2" pt="2" pb="3" px="2">
            {editField === 'query' && (
              <EditSearchFieldPage
                closePage={() => setEditField(null)}
                fieldName="query"
                placeholder="Search jobs..."
                suggestions={querySuggestions}
              />
            )}
            {editField === 'location' && (
              <EditSearchFieldPage
                closePage={() => setEditField(null)}
                fieldName="location"
                placeholder="ZIP Code"
              />
            )}
            {!editField && (
              <InitialPage
                submit={submit}
                values={values}
                editQuery={() => setEditField('query')}
                editLocation={() => setEditField('location')}
              />
            )}
          </Grid>
        </form>
      )}
    </Form>
  )
}

export default JobSearchForm
