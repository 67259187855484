import {useMountedState} from 'react-use'
import {useState, useCallback} from 'react'
import uniqBy from 'lodash/uniqBy'

import {
  useJobSearchLazyQuery,
  JobSearchQueryVariables,
} from 'src/graphql-generated'
import {sendJobSearchEvent} from 'src/lib/gsAnalytics'

const firstJobsTerms = [
  'recent high school',
  'entry level',
  'no experience',
  'ged',
  'diploma',
  'limited experience',
]
  .map((term) => `"${term}"`)
  .join(' OR ')
const firstJobsQueryScope = ['(', firstJobsTerms, ')'].join('')

export const useJobSearch = () => {
  const [doSearch, {loading, data, called, fetchMore: doFetchMore, variables}] =
    useJobSearchLazyQuery()
  const isMounted = useMountedState()
  const [loadingMore, setLoadingMore] = useState(false)
  console.log('called', called)

  if (!called) {
    sendJobSearchEvent()
  }

  const searchJobs = useCallback(
    (params: Readonly<JobSearchQueryVariables['search']>) => {
      const search = {
        ...params,
        query: [params.query, firstJobsQueryScope]
          .filter((term) => !!term)
          .join(' '),
        radius: 10,
      }

      doSearch({variables: {search}})
    },
    [doSearch]
  )

  if (!called) {
    return {
      searchJobs,
      loading,
      results: null,
      called,
    } as const
  }

  const results = data ? data.searchJobs : null

  if (loading || !results) {
    return {
      loading: true,
      called,
      searchJobs,
      searchQuery: variables?.search,
    }
  }

  const pageInfo = results.pageInfo

  const fetchMore = async () => {
    if (!isMounted() || !pageInfo.hasMore) {
      return
    }
    setLoadingMore(true)

    try {
      await doFetchMore({
        variables: {
          offset: pageInfo.end,
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) {
            return prev
          }

          if (!prev) {
            return fetchMoreResult
          }

          const results = uniqBy(
            [...prev.searchJobs.results, ...fetchMoreResult.searchJobs.results],
            (i) => i.id
          )
          return {
            searchJobs: {
              ...fetchMoreResult.searchJobs,
              results,
            },
          }
        },
      })
    } finally {
      if (isMounted()) setLoadingMore(false)
    }
  }

  return {
    searchJobs,
    loading,
    results,
    called,
    hasMore: pageInfo.hasMore,
    fetchMore,
    loadingMore,
    searchQuery: variables?.search,
  } as const
}
