import {useEffect, useState, useMemo} from 'react'
import {RouteComponentProps} from '@reach/router'
import {Helmet} from 'react-helmet-async'
import queryString from 'query-string'
import {navigate, Link} from 'gatsby'
import isEqual from 'lodash/isEqual'
import {usePreviousDistinct} from 'react-use'
import styled from '@emotion/styled'

import Layout from 'src/components/Layout'
import SEO from 'src/components/SEO'
import {useJobSearch} from 'src/hooks/useJobSearch'
import JobSearchForm from 'src/components/JobSearchForm'
import Loading, {LoadingContentPlaceholder} from 'src/components/Loading'
import JobCard from 'src/components/JobCard'
import Stack from 'src/components/Stack'
import {Job} from 'src/graphql-generated'
import {Flex, Box, PositionContainer} from 'src/components/Box'
import {Action} from 'src/components/Action'
import {PencilIcon} from 'src/components/icons'
import {Body, Header3} from 'src/components/text'
import {Divider} from 'src/components/dividers'
import * as styles from 'src/styles'
import {useIsLargeMedia} from 'src/hooks/useMedia'
import Carousel from 'src/components/Carousel'
import {localPaths} from 'src/urls'
import NoMoreJobs from 'src/components/NoMoreJobs'
import {Label} from 'src/components/form/input'

type JobResult = Pick<
  Job,
  | 'id'
  | 'company'
  | 'formattedLocationFull'
  | 'jobTitle'
  | 'onmousedown'
  | 'snippet'
  | 'url'
>

const addSpaceAfterSlash = (theString: string) =>
  theString.replace(/\/([^\s])/g, '/ $1')

const decorateJob = (job: JobResult): JobResult => ({
  ...job,
  jobTitle: addSpaceAfterSlash(job.jobTitle).trim(),
})

const IndeedAttribution = () => (
  <span id="indeed_at">
    <a href="https://www.indeed.com/" rel="nofollow">
      Jobs
    </a>{' '}
    by{' '}
    <a href="https://www.indeed.com/" rel="nofollow" title="Job Search">
      <img
        src="https://www.indeed.com/p/jobsearch.gif"
        style={{border: 0, verticalAlign: 'middle'}}
        alt="Indeed job search"
      />
    </a>
  </span>
)

const ToolLink = styled(Link)(({theme}) => ({
  margin: `0 ${theme.space[4]}`,
}))

const JobSearchResults: React.FunctionComponent<{
  fetchMore?: () => void
  hasMore?: boolean
  loadingMore?: boolean
  jobs: JobResult[]
}> = ({jobs, fetchMore, hasMore, loadingMore}) => {
  const isLargeMedia = useIsLargeMedia()

  if (jobs.length === 0) {
    return (
      <Box my="6">
        <Header3 as="p">No results found, try a different search.</Header3>
      </Box>
    )
  }

  const decoratedJobs = jobs.map(decorateJob)

  if (isLargeMedia) {
    return (
      <Flex
        height="450px"
        flexDirection="column"
        justifyContent="flex-end"
        mt="2"
      >
        <PositionContainer
          top="0"
          horizontalCenter
          width="100vw"
          maxWidth="1300px"
          position="absolute"
        >
          <Carousel containerHeight="400px" fetchMore={fetchMore}>
            {decoratedJobs.map((j, i) => (
              <JobCard key={i} job={j} />
            ))}
            {loadingMore ? (
              <LoadingContentPlaceholder size="small" />
            ) : !hasMore ? (
              <NoMoreJobs />
            ) : null}
          </Carousel>
        </PositionContainer>
      </Flex>
    )
  }

  return (
    <Box mt="3">
      <Stack containerHeight="400px" fetchMore={fetchMore}>
        {decoratedJobs.map((j, i) => (
          <JobCard key={i} job={j} />
        ))}
        {loadingMore ? (
          <Loading size="small" />
        ) : !hasMore ? (
          <NoMoreJobs />
        ) : null}
      </Stack>
    </Box>
  )
}

const SearchFormContainer = styled.div(
  {
    position: 'absolute',
    zIndex: 1,
    top: '2em',
    left: '-0.5em',
    right: '-0.5em',
    backgroundColor: 'white',
    borderRadius: '25px',
    ...styles.shadow.containerShadow,
  },
  ({theme}) => ({
    [styles.mediaQuery.large]: {
      ...styles.borders.simpleBorder(2),
      position: 'relative',
      top: 'unset',
      left: 'unset',
      right: 'unset',
      boxShadow: 'none',
      marginTop: theme.space[1],
    },
  })
)

const JobSearchResultsPage = ({location}: RouteComponentProps) => {
  const {
    loading,
    searchJobs,
    called,
    results,
    hasMore,
    fetchMore,
    loadingMore,
  } = useJobSearch()

  const [filtersOpen, setFiltersOpen] = useState(false)

  const searchValues = useMemo(
    () => queryString.parse(location?.search ?? ''),
    [location?.search]
  )

  const previousSearch = usePreviousDistinct(searchValues)

  const isLargeMedia = useIsLargeMedia()

  useEffect(() => {
    if (isEqual(previousSearch, searchValues)) {
      return
    }

    searchJobs(searchValues)
  }, [called, searchValues, searchJobs, previousSearch])

  return (
    <Layout>
      <SEO title="Job Search Results" />
      <Helmet>
        <script
          type="text/javascript"
          src="//gdc.indeed.com/ads/apiresults.js"
        ></script>
      </Helmet>

      <Flex
        position="relative"
        mt="2"
        mb={{_: 0, lg: 2}}
        flexDirection="column"
      >
        <Flex justifyContent="space-between">
          {filtersOpen && (
            <Flex ml="auto" flexDirection="column" justifyContent="flex-start">
              <Action onClick={() => setFiltersOpen(false)}>
                <Body>Close</Body>
              </Action>
            </Flex>
          )}
        </Flex>
        {filtersOpen ? (
          <SearchFormContainer>
            <JobSearchForm
              initialValues={searchValues}
              onSubmit={({jobType, ...values}) => {
                const searchValues = {
                  jobType: jobType ? jobType : undefined,
                  ...values,
                }
                navigate(localPaths.jobSearchResults(searchValues), {
                  replace: true,
                })
                !isLargeMedia && setFiltersOpen(false)
              }}
            />
          </SearchFormContainer>
        ) : (
          <Action
            aria-label="Edit Search"
            onClick={() => setFiltersOpen(true)}
            css={{textAlign: 'left'}}
          >
            <Flex
              border={`2px solid ${styles.theme.colors.grey2}`}
              px="2"
              py={{_: 1, lg: 1}}
              mt="1"
              borderRadius="15px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex flexDirection="column">
                <Label>Refine your search</Label>
                {searchValues.query || 'All jobs'} near {searchValues.location}
              </Flex>
              <Flex
                p="2"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                border="2px solid"
                borderColor="green"
              >
                <PencilIcon color="green" />
              </Flex>
            </Flex>
          </Action>
        )}
      </Flex>

      {isLargeMedia && <Divider thin mx={-3} />}

      {loading && <LoadingContentPlaceholder size="small" />}
      <Box position="relative">
        {results && (
          <JobSearchResults
            jobs={results.results}
            hasMore={hasMore}
            fetchMore={fetchMore}
            loadingMore={loadingMore}
          />
        )}
      </Box>
      <IndeedAttribution />
      {isLargeMedia && (
        <Flex
          mt="5"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Header3>We can help you get this job!</Header3>
          <Flex mt="4">
            <ToolLink to={localPaths.helpApplying}>
              I need help applying
            </ToolLink>
            <ToolLink to={localPaths.appliedNowWhat}>
              I applied here! Now what?
            </ToolLink>
            <ToolLink to={localPaths.sendText}>Text us a question</ToolLink>
          </Flex>
        </Flex>
      )}
    </Layout>
  )
}

export default JobSearchResultsPage
