export const sendAnalyticsEvent = (event: string, submitStatus?: string) => {
  if (typeof window == 'undefined') {
    return
  }

  window.dataLayer = window.dataLayer || []

  const data = {event: event, ...(submitStatus && {submitStatus: submitStatus})}

  window.dataLayer.push(data)
}

export function sendJobSearchEvent() {
  sendAnalyticsEvent('job zip code search')
}

export function sendAccountCreationEvent() {
  sendAnalyticsEvent('account creation', 'success')
}

export function sendProfileUpdateEvent() {
  sendAnalyticsEvent('profile update')
}
